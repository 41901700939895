import { PageProps } from 'gatsby';
import { TrackingData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Tracking';
import List from '~/containers/Espace/List';
import params from '~/params/tracking.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useAdminTasks from '~/utils/useAdminTasks';

const PageAdminTracking: FC<PageProps & UserProps> = ({ location, user }) => {
  const { t } = useTranslation();
  const items = useAdminTasks({
    pathname: location.pathname,
  });

  return (
    <TemplateAdmin>
      <List
        itemList={Item}
        model={
          new TrackingData({
            params,
            wheres: {
              parent: '',
            },
          })
        }
        search={location.search}
        subMenu={items}
        title={t('tasks.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminTracking, 'admin');
